<template>
    <div class="top barging-container">
      <div class="flex flex-row justify-between p-1 laptop:pb-2 desktop:pb-8">
        <tabs :tabs="tabsList" @updateIndex="updateIndex" :currentIndex="upIndex"></tabs>
      </div>

      <div class="barging-card" style="padding:0px 0px; background-color:#f2f7fa; border-radius: 10px;">
        <div class="w-full searchBar" >
          <div class="flex flex-wrap justify-end" v-if="upIndex == '0'">
            <div class="flex flex-row py-2 pr-2">
              <el-input v-model="params.searchValue" size="mini" clearable placeholder="Search" :style="{ width: '100%' }" @keyup.enter.native="getList"></el-input>
            </div>
            <div class="flex flex-row py-2 pr-2">
              <el-date-picker v-model="params.startDate" size="mini" placeholder="Start Date" prefix-icon="el-icon-date" type="datetime" :style="{ width: '100%' }"
                format="dd/MM/yyyy HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" @change="handleSelectChange">
              </el-date-picker>
            </div>
            <div class="flex flex-row py-2">
              <el-date-picker v-model="params.endDate" size="mini" placeholder="End Date" prefix-icon="el-icon-date" type="datetime" :style="{ width: '100%' }"
                format="dd/MM/yyyy HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" @change="handleSelectChange">
              </el-date-picker>
            </div>
            <div class="flex flex-row pl-2 py-2">
              <!-- <el-button size="mini" type="primary" @click="getList">Search</el-button> -->
              <el-button size="mini" type="primary" @click="handleExportExcel" :loading="isLoadingExportExcel">Export Excel</el-button>
            </div>
          </div>
          <div class="flex flex-wrap justify-end" v-else>
            <div class="flex flex-row py-2 pr-2">
              <el-select size="mini" class="Pendingselect" :clearable="true" v-model="selectDate" @change="handleSelectChange" placeholder="Weekly">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </div>
          </div>
        </div>

        <div class="box-card barging-table" v-bind:class="{'mobile-view':isMobileScreen}">
          <div class="w-full Comtable" v-bind:class="{ completedTable: upIndex == '0' }">
            <billingTable v-if="upIndex == '0'" key="allBillTable"  :listData="listData"></billingTable>
            <el-table v-else 
              key="historyTable" 
              :data="historyListData"
              :header-cell-style="{height: '55px',background: '#fff','border-bottom': '2px solid #D6D9E1',}"
              :cell-style="getCellStyle"
              table-layout="auto"
              height="100%"
            >
              <el-table-column align="center" prop="startTime" label="Start Date Time" min-width="200" :formatter="formatDateTime" sortable></el-table-column>
              <el-table-column align="center" prop="endTime" label="End Date Time" min-width="200" :formatter="formatDateTime" sortable></el-table-column>
              <el-table-column align="center" prop="type" label="Type" min-width="200"></el-table-column>
              <el-table-column align="center" prop="duration" label="Duration(Day)" min-width="200" sortable></el-table-column>
              <el-table-column align="center" prop="createTime" label="Sent Date Time" min-width="200" :formatter="formatDateTime" sortable></el-table-column>
              <el-table-column align="center" prop="action" label="Action" min-width="200">
                <tempate slot-scope="scope">
                  <el-button type="primary" size="mini" @click="handleHistoryBillTable(scope.row.startTime,scope.row.endTime)">View</el-button>
                  <el-button type="primary" size="mini" @click="sendBillingEmail(scope.row.startTime,scope.row.endTime)" :loading="isLoadingResend" >Resend</el-button>
                </tempate>
              </el-table-column>
            </el-table>
          </div>
         </div>
        <div class="barging-pagination">
          <pagination
            v-show="total > 0"
            :total="total"
            :page.sync="pageParams.pageNum"
            :limit.sync="pageParams.pageSize"
            :pageSizes="[20, 30, 50, 100]"
            @pagination="getList"
          ></pagination>
        </div>
      </div>
      <el-dialog :visible.sync="historyBillDialog" title="" :width="isMobileScreen ? '100%' : '90%'">
        <div class="w-full Comtable">
          <billingTable :listData="historyBillData" key="historyBillTable" ></billingTable>

        </div>
        <div class="barging-pagination">
          <pagination
            v-show="historyBillTotal > 0"
            :total="historyBillTotal"
            :page.sync="historyBillParams.pageNum"
            :limit.sync="historyBillParams.pageSize"
            :pageSizes="[10, 20, 50, 100]"
            @pagination="getHistoryBillTable"
          ></pagination>
        </div>
      </el-dialog>
    </div>
  </template>
  
  <script>
  import tabs from "@/components/tabs.vue";
  import { getAllBillingRecord, getHistoryBillingRecord, getBillingRecordQuantity, exportBillingRecordExcel,sendBillingScheduledEmailByDate } from "@/services";
  import PdfView from "@/components/common/pdf-view.vue";
  import { mapState } from "vuex";
  import billingTable from "./component/billing-table.vue";
  export default {
    name: "Billing Record",
    components: { tabs, PdfView, billingTable },
  
    data() {
      return {
        tabsList: [
          { label: "All Billing Record", count: 0, index: "0" },
          { label: "History", count: 0, index: "1" },
        ],
        upIndex: 0,
        listData: [],
        historyListData: [],
        searchValue: "",
        options: [
          {
            value: "Weekly",
            label: "Weekly",
          },
          {
            value: "Monthly",
            label: "Monthly",
          },
          {
            value: "Yearly",
            label: "Yearly",
          },
          {
            value: "Custom",
            label: "Custom",
          },
        ],
        selectDate: "",
        pageParams: {
          pageNum: 1,
          pageSize: 20,
        },
        total: 0,
        operationData: {
          src: "",
          show: false,
        },
        selectedRows: [],
        fetchPdfFailedDialog: false,
        fetchPdfFailedId:[],
        fetchPdfSuccessId:[],
        startDate: "",
        endDate: "",
        params: {
          searchValue: "",
          pageNum: 1,
          pageSize: 20,
          startDate: null,
          endDate: null,
        },
        isLoadingExportExcel: false,
        completedSearchPlaceholder: "Search Barge",
        historyBillTotal: 0,
        historyBillDialog: false,
        historyBillData: [],
        historyBillParams: {
          pageNum: 1,
          pageSize: 10,
          searchValue: "",
          startDate: "",
          endDate: ""
        },
        isLoadingResend: false,
      };
    },
    computed: {
      ...mapState(["userInfo","screenType"]),
      isMobileScreen(){
        return this.screenType?.isMobileScreen??false;
      }
    },
    created() {
      this.getList();
    },
    methods: {
      updateIndex(index) {
        this.upIndex = index;
        this.getList();
      },
      handleSelectChange() {
        this.getList();
      },
      getList() {
        getBillingRecordQuantity().then((res) => {
          this.tabsList = [
            { label: "All Billing Record", count: res?.data?.allBillingRecord, index: "0" },
            { label: "History", count: res?.data?.history, index: "1" },
          ];
        });
        if(this.upIndex == '0'){
          let data = {
            ...this.params,
            ...this.pageParams,
          };
          getAllBillingRecord(data).then((res) => {
            this.listData = res?.data?.rows;
            this.total = res?.data?.total;
          })
        }else{
          let data = {
            type: this.selectDate? this.selectDate : "Weekly"
          };
          getHistoryBillingRecord(data).then((res) => {
            this.historyListData = res?.data?.rows;
            this.total = res?.data?.total;
          });
        }
      },
      getCellStyle({ rowIndex }) {
        if (rowIndex % 2 === 1) {
          return {
            backgroundColor: "rgba(255,255,255,0.2)",
          };
        } else {
          return {
            backgroundColor: "rgba(228,228,228,0.2)",
          };
        }
      },
      handleExportExcel() {
        this.isLoadingExportExcel = true;

        let params = {
          searchValue: this.params.searchValue,
          startDate: this.params.startDate,
          endDate: this.params.endDate,
        }
        exportBillingRecordExcel(params)

        this.isLoadingExportExcel = false;
      },
      formatDateTime(row, column, cellValue) {
        if (!cellValue) return '';
        const formattedTimestamp = cellValue.split('.')[0];
        return formattedTimestamp.replace('T', ' ');
      },
      handleHistoryBillTable(startTime, endTime){
        this.historyBillParams.startDate = startTime
        this.historyBillParams.endDate = endTime
        this.getHistoryBillTable()
      },
      getHistoryBillTable(){
        let data = {
          ...this.historyBillParams
        };
        getAllBillingRecord(data).then((res) => {
          this.historyBillData = res?.data?.rows;
          this.historyBillTotal = res?.data?.total
        })
        this.historyBillDialog = true
      },
      async sendBillingEmail(startTime, endTime){
        const startDate = startTime.substring(0, 10);
        const endDate = endTime.substring(0, 10);
        let data = {
          startDate: startDate,
          endDate: endDate
        }
        
        try {
          const res = await sendBillingScheduledEmailByDate(data);
          this.$message.success('Resend Email Success');
          this.getList();
        } catch (error) {
          // this.$message.success('Error sending email:' + error);
        }
      },
    },
  };
  </script>
  
  <style scoped lang="scss">
  .Topinp {
    position: relative;
  }
  .Topinput {
    display: flex;
    position: absolute;
    top: -65px;
    right: -40px;
    .Comipt {
      width: 300px;
      height: 46px;
    }
    .Pendingselect {
      width: 221px;
      height: 46px;
      margin-left: 16px;
    }
  }
  .Reqcol {
    display: flex;
    justify-content: center;
    height: calc(90vh - 80px - 32 * 2px - 56px);
    overflow-y: scroll;
    .ReqTable {
      width: 1000px;
    }
    .el-button--default {
      color: #fff;
      background-color: #008CDB;
    }
    .el-button--default.is-plain:hover {
      color: #fff;
      background: #008CDB;
      border-color: #008CDB;
    }
    .el-button--default:focus {
      color: #fff !important;
      background: #008CDB !important;
      border-color: #008CDB;
    }
  }
  ::v-deep {
    .el-input__icon.el-icon-search {
      height: 32px !important;
    }
  }
  ::v-deep .pagination-container {
    background: #f2f7fa;
  }
  ::v-deep .el-input__suffix-inner {
    padding-right: 5px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    svg {
      width: 13px;
      height: 13px;
      cursor: pointer;
    }
  }
  ::v-deep .el-table .cell {
    word-break:normal;
  }
  
  @media (max-width: 1023px) {
    .Comtable {
      height: calc(100vh - 16rem);
    }
    .completedTable {
        height: calc(100vh - 18.5rem);
    }
    .mobile-view{
      .Comtable {
        height: calc(100vh - 15.5rem);
      }
      .completedTable {
        height: calc(100vh - 370px);
      }
    }
  }
  @media (min-width: 1024px) {
    .Comtable {
      height: calc(100vh - 14rem);
    }
    .barging-card{
      margin-top: 0px !important;
    }
  }
  @media (min-width: 1441px) {
    .barging-card{
      height: calc(100vh - 160px);
    }
    .Comtable {
      height: calc(100vh - 15rem);
    }
  }
  .searchBar{
    width: 100% !important;
    background-color: #f3f7fa;
    // border-radius: 10px;
    // border: 1px solid #D5DDED;
    font-size: 11px;
    color: #1D1B20;
    font-family: 'Roboto';
    padding: 0px 20px 0px 0px;
  
    .label {
      padding: 5px;
      color: #1D1B20;
      font-weight: 600;
      text-align: right;
      width: 90px;
    }
  }
  </style>
  