<template>
  <el-table key="allBillingTable" 
    :data="listData"
    :header-cell-style="{height: '55px',background: '#fff','border-bottom': '2px solid #D6D9E1',}"
    :cell-style="getCellStyle"
    @row-click="handleClick"
    table-layout="auto"
    height="100%"
  >
    <el-table-column align="center" prop="serialNumber" label="S/N" min-width="50" sortable></el-table-column>
    <el-table-column align="center" prop="dateTime" label="Date and Time" min-width="100" sortable></el-table-column>
    <el-table-column align="center" prop="customerCompany" label="Customer Company" min-width="200" sortable></el-table-column>
    <el-table-column align="center" prop="item" label="Item" min-width="250" sortable></el-table-column>
    <el-table-column align="center" prop="shipName" label="Ship Name" min-width="100" sortable></el-table-column>
    <el-table-column align="center" prop="product" label="Product" min-width="100" sortable></el-table-column>
  </el-table>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Billing Table",
  props: {
    listData: {
      type: String
    },
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState(["userInfo","screenType"]),
    isMobileScreen(){
      return this.screenType?.isMobileScreen??false;
    }
  },
  created() {
  //   this.handleRoleAccess();
    // this.getList();
  },
  methods: {
    getCellStyle({ rowIndex }) {
      if (rowIndex % 2 === 1) {
        return {
          backgroundColor: "rgba(255,255,255,0.2)",
        };
      } else {
        return {
          backgroundColor: "rgba(228,228,228,0.2)",
        };
      }
    },
    formatCompleteTime(row, column, cellValue){
      if (!cellValue) return ''; 
      return cellValue.replace('T', ' ');
    }
  },
};
</script>

<style scoped lang="scss">
::v-deep .el-table .cell {
  word-break:normal;
}

@media (max-width: 1023px) {
  .Comtable {
    height: calc(100vh - 16rem);
  }
  .completedTable {
      height: calc(100vh - 18.5rem);
  }
  .mobile-view{
    .Comtable {
      height: calc(100vh - 15.5rem);
    }
    .completedTable {
      height: calc(100vh - 370px);
    }
  }
}
@media (min-width: 1024px) {
  .Comtable {
    height: calc(100vh - 14rem);
  }
  .barging-card{
    margin-top: 0px !important;
  }
}
@media (min-width: 1441px) {
  .barging-card{
    height: calc(100vh - 160px);
  }
  .Comtable {
    height: calc(100vh - 15rem);
  }
}
</style>
